import React from "react";
import './Contact.scss'

const Contact = () => {
  return (
    <div>
      <h1>Contact Information</h1>
      <div className="footer-item text-center">
        <h6 className="fs-17 fw-6">Contact</h6>
        <ul>
          <li>
            <span>
              <i className="fas fa-phone"></i>
            </span>
            <span className="fs-15"> +91 9888180756</span>
          </li>
          <li>
            <span>
              <i className="fas fa-envelope"></i>
            </span>
            <span className="fs-15"> assocollectio@gmail.com</span>
          </li>
          <li>
            <span>
              <i className="fas fa-home"></i>
            </span>
            <span className="fs-15">
              {' '}KH NO 787 MUSTAFABD COLONY, LONI GHAZIABAD, GHAZIABAD,
              GHAZIABAD,UTTAR PARDESH, 201102
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
