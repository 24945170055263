export const categories = [
  {
    id: 1,
    name: "Medical Uniform",
    image:
      "https://ihpi.umich.edu/sites/default/files/styles/open_graph/public/images/news/Media%2520Monitoring%2520Clothes.jpg?itok=XaNenvDL",
    createdAt: "2023-09-17T10:15:00.000Z",
    updatedAt: "2023-09-17T10:15:00.000Z",
  },
  {
    id: 2,
    name: "Medical Equipment",
    image:
      "https://d1a9v60rjx2a4v.cloudfront.net/2017/02/09/06_55_49_440_Surgical_Instruments_B_017c.jpg",
    createdAt: "2023-09-17T10:30:00.000Z",
    updatedAt: "2023-09-17T10:30:00.000Z",
  },
  {
    id: 3,
    name: "Medical Kit",
    image: "https://www.mfasco.com/4890z.jpg?resizeid=-6",
    createdAt: "2023-09-17T10:45:00.000Z",
    updatedAt: "2023-09-17T10:45:00.000Z",
  }
];
