import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from '../../store/cartSlice';

const Order = () => {
    const [cart, setCart]=useState([])
    const [shownoorder, setShownoorder]=useState(false)
    const dispatch=useDispatch();
    const currentcart=useSelector((cartItems)=>{
        return cartItems.cart.data;
    })
    
    function generate16DigitNumber() {
        const min = 1e15; // Minimum 16-digit number
        const max = 1e16 - 1; // Maximum 16-digit number
      
        // Generate a random number within the specified range
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      
        // Convert the random number to a string to preserve leading zeros
        const random16DigitNumber = randomNumber.toString();
      
        return random16DigitNumber;
      }
      // Example usage:
      const sixteenDigitNumber = generate16DigitNumber();
    useEffect(()=>{
        if(currentcart.length){
            console.log("Inside useEffect", currentcart)
            setCart([...cart, ...currentcart])
            setShownoorder(true);
            alert("Payment success")
            dispatch(clearCart())
        }
    },[currentcart])
    console.log("Redux state", cart)
  return (
    <div>
        {cart.length && shownoorder ?<div>
        <h1>Order confirmed</h1>
        <h2>Your order has been placed successfully</h2><br/><br/>
        <h2 style={{textOverflow:'ellipsis', overflow:'hidden'}}>Order ID: {sixteenDigitNumber}</h2><br/><br/>
        <h3>For: {cart.map((item)=>{
            return <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}} key={sixteenDigitNumber}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img style={{display:'inline', width:'40%', height: '70px'}} src={item?item.images[0]:''} alt={item.title} /> 
                <h3 style={{maxWidth:'300px', marginLeft:'40px'}}>{item?.title}</h3><br/>
                <h3 style={{minWidth:'140px'}}>{item.quantity>1?`X ${item.quantity} (quantity)`:" "}</h3>
                </div>
            </div>
        })}</h3><br/><br/>
        </div>:"No orders"}
    </div>
  )
}

export default Order