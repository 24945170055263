export const products = [
  {
    id: 10,
    title: "Medical Scrubs Set",
    price: 199900,
    description:
      "High-quality medical scrubs set for healthcare professionals. Includes a comfortable top and pants. Available in various sizes and colors.",
    images: [
      "https://i5.walmartimages.com/asr/f8ab32f5-a2e2-4f6d-b5d3-874eba5a8ed6_2.380d472067e69425e933e4cf312dc26f.jpeg",
      "https://ae01.alicdn.com/kf/HTB1DjuWXDjxK1Rjy0Fnq6yBaFXam/14-Colors-Men-s-Scrubs-Nursing-Uniform-Medical-Workwear-Classic-Stylish-Premium-Fabric-Dentist-Work-Clothing.jpg",
      "https://i.pinimg.com/736x/e2/92/26/e29226c525d4757d3bce6ccbcaab2460.jpg",
    ],
    createdAt: "2023-09-17T10:15:00.000Z",
    updatedAt: "2023-09-17T10:15:00.000Z",
    category: {
      id: 1,
      name: "Medical Uniform",
      image:
        "https://ihpi.umich.edu/sites/default/files/styles/open_graph/public/images/news/Media%2520Monitoring%2520Clothes.jpg?itok=XaNenvDL",
      createdAt: "2023-09-17T10:15:00.000Z",
      updatedAt: "2023-09-17T10:15:00.000Z",
    },
  },
  {
    id: 11,
    title: "Digital Blood Pressure Monitor",
    price: 1000000,
    description:
      "Accurate digital blood pressure monitor for home and medical use. Large LCD display and easy one-button operation.",
    images: [
        "https://medcom.uiowa.edu/theloop/wp-content/uploads/2021/06/UI-Home-Solutions-Medical-Supply-Store_stock-images_6.24.21.png",
        "https://images.search.yahoo.com/images/view;_ylt=AwrgLEYAQgdlkv0ByeyJzbkF;_ylu=c2VjA3NyBHNsawNpbWcEb2lkAzMyNjQxMTVlNTcwYmE5YzFmMjg4Mzk2MDAwOTRhMTgyBGdwb3MDNTUEaXQDYmluZw--?back=https%3A%2F%2Fimages.search.yahoo.com%2Fsearch%2Fimages%3Fp%3Dmedical%2Bequipment%26type%3DE211US714G0%26fr%3Dmcafee%26fr2%3Dpiv-web%26tab%3Dorganic%26ri%3D55&w=1400&h=600&imgurl=www.hyms.ac.uk%2Fassets%2Fimages%2Fmedical-equipment.jpg&rurl=https%3A%2F%2Fwww.hyms.ac.uk%2Fpostgraduate-research-degrees%2Fmd-in-medical-sciences&size=305.2KB&p=medical+equipment&oid=3264115e570ba9c1f28839600094a182&fr2=piv-web&fr=mcafee&tt=MD+in+Medical+Sciences+%7C+Hull+York+Medical+School&b=0&ni=21&no=55&ts=&tab=organic&sigr=u0lTdLo_4aVA&sigb=5ivcCvtbsXf3&sigi=PXK6Z5Put1lj&sigt=3e1OF9b9Fs5b&.crumb=NfXYQeTkJSM&fr=mcafee&fr2=piv-web&type=E211US714G0",
      "http://happyhealthyhub.com/wp-content/uploads/2013/03/Medical-Supplies.jpg",
    ],
    createdAt: "2023-09-17T10:30:00.000Z",
    updatedAt: "2023-09-17T10:30:00.000Z",
    category: {
      id: 2,
      name: "Medical Equipment",
      image:
        "https://d1a9v60rjx2a4v.cloudfront.net/2017/02/09/06_55_49_440_Surgical_Instruments_B_017c.jpg",
      createdAt: "2023-09-17T10:30:00.000Z",
      updatedAt: "2023-09-17T10:30:00.000Z",
    },
  },
  {
    id: 12,
    title: "First Aid Kit",
    price: 1299000,
    description:
      "Comprehensive first aid kit with essential supplies for emergencies. Compact and portable design for home and travel.",
    images: [
      "https://i5.walmartimages.com/asr/3867bdc9-c5a3-4108-bccc-6c54e393cccb_2.b131dc14379244dd37163493d3e994e6.jpeg",
      "https://static.grainger.com/rp/s/is/image/Grainger/3RTZ5_AS02",
      "https://myoutdoorpal.com/wp-content/uploads/2020/01/7789-a0032b.jpg",
    ],
    createdAt: "2023-09-17T10:45:00.000Z",
    updatedAt: "2023-09-17T10:45:00.000Z",
    category: {
      id: 3,
      name: "Medical Kit",
      image: "https://www.mfasco.com/4890z.jpg?resizeid=-6",
      createdAt: "2023-09-17T10:45:00.000Z",
      updatedAt: "2023-09-17T10:45:00.000Z",
    },
  },

  {
    id: 13,
    title: "Nurse Uniform Set",
    price:99999,
    description:
      "Stylish and comfortable nurse uniform set. Includes a top, pants, and a matching hat. Ideal for medical professionals.",
    images: [
      "https://ae01.alicdn.com/kf/HTB1XVMxRpXXXXcmXVXXq6xXFXXXx/Brands-Emergency-Room-Medical-Clothing-Uniforms-Cotton-Female-Scrub-Clothes-Male-Doctor-uniforms-3pc-suits.jpg",
      "https://ae01.alicdn.com/kf/HTB1Mz9cPXXXXXaWXVXXq6xXFXXXa/New-Arrival-Summer-Short-Sleeve-Nurse-Uniform-Medical-Lab-Coat-Pharmacy-Uniforms-Hospital-Doctors-White-Coat.jpg",
      "https://www.medelita.com/media/images/2017-scrubs-banner-men.jpg",
    ],
    createdAt: "2023-09-17T11:00:00.000Z",
    updatedAt: "2023-09-17T11:00:00.000Z",
    category: {
      id: 1,
      name: "Medical Uniform",
      image:
        "https://ihpi.umich.edu/sites/default/files/styles/open_graph/public/images/news/Media%2520Monitoring%2520Clothes.jpg",
      createdAt: "2023-09-17T11:00:00.000Z",
      updatedAt: "2023-09-17T11:00:00.000Z",
    },
  },
  {
    id: 14,
    title: "Medical Examination Table",
    price:1199999,
    description:
      "Durable and adjustable medical examination table for healthcare facilities. Provides comfort and support for patients.",
    images: [
      "https://www.goodnet.org/photos/620x0/30719_hd.jpg",
      "https://i0.wp.com/theholistichomestead.org/wp-content/uploads/2016/05/home-medical-equipment.jpg",
      "https://www.fcgov.com/recycling-item-images/img/medical-equipment-and-supplies.jpg",
    ],
    createdAt: "2023-09-17T11:15:00.000Z",
    updatedAt: "2023-09-17T11:15:00.000Z",
    category: {
      id: 2,
      name: "Medical Equipment",
      image:
        "https://d1a9v60rjx2a4v.cloudfront.net/2017/02/09/06_55_49_440_Surgical_Instruments_B_017c.jpg",
      createdAt: "2023-09-17T11:15:00.000Z",
      updatedAt: "2023-09-17T11:15:00.000Z",
    },
  },
  {
    id: 16,
    title: "Compact First Aid Kit",
    price: 1049966,
    description:
      "Compact and portable first aid kit with essential supplies for emergencies. Fits easily in bags, cars, and backpacks.",
    images: [
      "https://static.grainger.com/rp/s/is/image/Grainger/3EWK1_AS01",
      "https://www.survivalmetrics.com/store/media/ecom/prodxl/homeandvehiclepluskithardsoftcase-large4.jpg",
      "https://i1.wp.com/moderndepot.co/wp-content/uploads/2020/09/3_69e9f9d0-4944-427f-bf81-6dd310f4e0cf.png?fit=1000%2C1000&ssl=1",
    ],
    createdAt: "2023-09-17T11:45:00.000Z",
    updatedAt: "2023-09-17T11:45:00.000Z",
    category: {
      id: 3,
      name: "Medical Kit",
      image: "https://www.mfasco.com/4890z.jpg",
      createdAt: "2023-09-17T11:45:00.000Z",
      updatedAt: "2023-09-17T11:45:00.000Z",
    },
  },
  {
    id: 17,
    title: "Medical Laboratory Coat",
    price: 111999,
    description:
      "Professional medical laboratory coat for researchers and healthcare workers. Made from durable and easy-to-clean material.",
    images: [
      "https://sparklerental.com/wp-content/uploads/2021/05/medical-uniforms.jpg",
      "https://m.media-amazon.com/images/I/81RHObhTEPL._AC_UL640_QL65_.jpg",
      "https://apparel-point.com/wp-content/uploads/2021/03/apparel-healthcare-uniform-1024x1024.jpg",
    ],
    createdAt: "2023-09-17T12:00:00.000Z",
    updatedAt: "2023-09-17T12:00:00.000Z",
    category: {
      id: 1,
      name: "Medical Uniform",
      image:
        "https://ihpi.umich.edu/sites/default/files/styles/open_graph/public/images/news/Media%2520Monitoring%2520Clothes.jpg",
      createdAt: "2023-09-17T12:00:00.000Z",
      updatedAt: "2023-09-17T12:00:00.000Z",
    },
  },

  {
    id: 18,
    title: "Digital Thermometer",
    price:500000,
    description:
      "Digital thermometer for quick and accurate temperature measurements. Suitable for home and clinical use.",
    images: [
      "https://i5.walmartimages.com/asr/7d8357dd-d959-48eb-acb9-dacfc09dbeae_1.eff75fb35c997ba89d93802cfa442b68.jpeg",
      "https://www.diehardsurvivor.com/wp-content/uploads/sites/905/2015/12/medical-supplies.jpg",
      "https://www.ustradebank.com/assets/images/Medical-supplies.jpg",
    ],
    createdAt: "2023-09-17T12:15:00.000Z",
    updatedAt: "2023-09-17T12:15:00.000Z",
    category: {
      id: 2,
      name: "Medical Equipment",
      image:
        "https://d1a9v60rjx2a4v.cloudfront.net/2017/02/09/06_55_49_440_Surgical_Instruments_B_017c.jpg",
      createdAt: "2023-09-17T12:15:00.000Z",
      updatedAt: "2023-09-17T12:15:00.000Z",
    },
  },
  {
    id: 19,
    title: "Emergency Medical Kit",
    price:500000,
    description:
      "Comprehensive emergency medical kit with supplies for first aid and minor injuries. A must-have for home and travel safety.",
    images: [
      "https://cdn.shopify.com/s/files/1/0252/0547/3316/collections/Traveler_2_1200x1200.jpg",
      "https://allthingsfirstaid.americommerce.com/resize/Shared/Images/products/fieldtex/medical-supplies-ft-kit.jpg",
      "https://i5.walmartimages.com/asr/0bcb7e57-bc80-4df6-b68c-a27a8be6d0c7_1.02383e2bf27d07752a7bb263b83f22bd.jpeg",
    ],
    createdAt: "2023-09-17T12:30:00.000Z",
    updatedAt: "2023-09-17T12:30:00.000Z",
    category: {
      id: 3,
      name: "Medical Kit",
      image: "",
      createdAt: "2023-09-17T12:30:00.000Z",
      updatedAt: "2023-09-17T12:30:00.000Z",
    },
  },
  {
    id: 20,
    title: "Disposable Medical Face Masks",
    price: 129900,
    description:
      "Pack of 50 disposable medical face masks. 3-ply design for effective filtration. Suitable for healthcare and everyday use.",
    images: [
      "https://ppe.phelpsusa.com/media/2020/04/mask.png",
      "https://health-options.co.uk/wp-content/uploads/2020/10/medical-laboratory-technician-holding-set-sterile-disposable-instruments-1536x1024.jpg",
      "https://tse4.mm.bing.net/th?id=OIP.G8ryk7gKiNf5luMwaMU90AHaJB&pid=Api&P=0&h=180",
    ],
    createdAt: "2023-09-17T12:45:00.000Z",
    updatedAt: "2023-09-17T12:45:00.000Z",
    category: {
      id: 2,
      name: "Medical Equipment",
      image:
        "https://d1a9v60rjx2a4v.cloudfront.net/2017/02/09/06_55_49_440_Surgical_Instruments_B_017c.jpg",
      createdAt: "2023-09-17T12:45:00.000Z",
      updatedAt: "2023-09-17T12:45:00.000Z",
    },
  },
  {
    id: 21,
    title: "Nurse's Scrub Set",
    price: 149900,
    description:
      "Comfortable nurse's scrub set with a top and pants. Available in various sizes and colors. Ideal for medical professionals.",
    images: [
      "https://www.primeuniformsupply.com/wp-content/uploads/sites/762/2016/04/medical-scrubs-rental.jpg",
      "https://i.ebayimg.com/00/s/MTIwMFgxMjAw/z/BrsAAOSwOlBemr2n/$_57.JPG",
      " https://image.made-in-china.com/2f0j00MnOESymWQvzr/Medical-Fabric-Cotton-Polyester-Flip-Collar-Doctor-Uniform-for-Female.jpg",
    ],
    createdAt: "2023-09-17T13:00:00.000Z",
    updatedAt: "2023-09-17T13:00:00.000Z",
    category: {
      id: 1,
      name: "Medical Uniform",
      image:
        "https://ihpi.umich.edu/sites/default/files/styles/open_graph/public/images/news/Media%2520Monitoring%2520Clothes.jpg",
      createdAt: "2023-09-17T13:00:00.000Z",
      updatedAt: "2023-09-17T13:00:00.000Z",
    },
  },
  {
    id: 23,
    title: "Emergency First Aid Handbook",
    price: 799000,
    description:
      "Comprehensive emergency first aid handbook with step-by-step instructions for handling common injuries and emergencies.",
    images: [
      "https://i5.walmartimages.com/asr/8fa30b7e-7f80-4a61-9032-a36d8ec4b33b_1.5ea38f697769e9f8f366db27fb323e07.jpeg",
      "https://www.adventuremedicalkits.com/media/catalog/product/cache/3/thumbnail/1250x1250/9df78eab33525d08d6e5fb8d27136e95/U/f/UfeB8dRi.jpg",
      "https://images-na.ssl-images-amazon.com/images/I/71IByW1T-OL.jpg",
    ],
    createdAt: "2023-09-17T13:30:00.000Z",
    updatedAt: "2023-09-17T13:30:00.000Z",
    category: {
      id: 3,
      name: "Medical Kit",
      image: "https://www.mfasco.com/4890z.jpg",
      createdAt: "2023-09-17T13:30:00.000Z",
      updatedAt: "2023-09-17T13:30:00.000Z",
    },
  },
  {
    id: 26,
    title: "Medical Lab Coat",
    price: 212900,
    description:
      "Professional medical lab coat with multiple pockets. Available in various sizes. Ideal for laboratory and healthcare work.",
    images: [
      "https://www.corporatewear.sg/wp-content/uploads/2017/02/medical-2.jpg",
      "https://ae01.alicdn.com/kf/HTB1kxzgdsic_eJjSZFnq6xVwVXal/Monogrammed-Lab-Coat-Top-Nursing-Medical-Uniform-Apparel-Doctor-Scrubs-Lab-Jacket-Stethoscope-Professor-Scientist-Costume.jpg_640x640.jpg",
      "https://ae01.alicdn.com/kf/HTB1Bw71IpXXXXbwXFXXq6xXFXXX1/Free-Shipping-OEM-surgical-clothing-dark-green-doctor-suit-hospital-workwear-physician-service-uniforme-medico-for.jpg_640x640.jpg",
    ],
    createdAt: "2023-09-17T14:15:00.000Z",
    updatedAt: "2023-09-17T14:15:00.000Z",
    category: {
      id: 1,
      name: "Medical Uniform",
      image:
        "https://ihpi.umich.edu/sites/default/files/styles/open_graph/public/images/news/Media%2520Monitoring%2520Clothes.jpg",
      createdAt: "2023-09-17T14:15:00.000Z",
      updatedAt: "2023-09-17T14:15:00.000Z",
    },
  },
];
